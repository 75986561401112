input {
    width: 50%;
    border: none;
    font-size: 3vh;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: black;
    margin-left: 15px;
}

/* For WebKit browsers (Chrome, Edge) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    background-color: transparent !important;
    color: black !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
}

/* For Firefox */
input:-moz-autofill {
    background-color: transparent !important;
    color: black !important;
}
input:focus {
    outline: none; /* Remove the outline when focused */
}