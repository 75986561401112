@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

* {
    padding: 0;
    margin: 0;
}

.footer-distributed {
    /* background-image: url('footerbg'); */
    /* padding: 20px; */
    display: flex;
    text-align: center;
    border-radius: 50px 50px 0 0;
    font-family: 'Roboto', sans-serif;
    position: relative;
    min-height: 150px; /* Or any value suitable for your design */
    background-size: cover; /* Ensure the image covers the container */
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;
    align-items: center;
    font-family: 'Sylfaen', sans-serif;
    /* flex-direction: column; */
}

.footer-left {
    margin: 20px 0;
}

.footer-logo {
    width: 150px;
    margin-bottom: 10px;
}

.footer-left h3 {
    font-size: 36px;
    margin: 10px 0;
}

.social-icons a {
    margin: 0 10px;
    color: black;
    font-size: 2rem;
    text-decoration: none;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #ff66ff /* Accent color on hover */
}

.contact-details {
    display: flex;
    margin: 10px 0;
    font-size: 18px;
    /* line-height: 1.8; */
}

.contact-details i {
    margin-right: 10px;
    cursor: pointer;
}

.footer-bottom {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px 0;
    width: 100%;
    border-radius: 50px 50px 0 0;
    position: absolute;
    bottom: 0;
}

.footer-bottom p {
    font-size: 16px;
    color: white;
    text-align: center;
}

.heart {
    color: red;
    font-size: 18px;
}

.contact-info {
    margin-right: 5rem;
    margin-bottom: 0rem;
}
/* Styles for larger screens */

/* Media query for very small screens */
@media (max-width: 600px) {
    footer {
        display: flex;
        flex-direction: column !important;
        border-top: 0px !important;
    }

    /* .footer-distributed .footer-left {
        display: flex !important;
        flex-direction: column;
        width: 100% !important;
        margin-bottom: 40px !important;
        text-align: center !important;
        height: auto;
    } */

    .footer-distributed .footer-center,
    .footer-distributed .footer-center1 {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center items horizontally */
        text-align: center; /* Center text */
    }

    .contact-details {
        width: auto;
        text-align: center;
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        margin-top: 0.5rem;
        margin-bottom: 0rem;
        font-size: 18px;
        gap: 0;
    }
    .contact-info {
        margin: 0.5rem auto; /* Top/Bottom margin: 0.5rem, horizontal margin: auto */
    }

    .contact-details {
        text-align: center; /* Optional: Center the text for better alignment */
    }
}

/* Add additional styles as needed... */

/* 
.footer-center {
    margin-left: 5%;
}

.footer-center1 {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
}

html {
    background-color: #eaf0f2;
}

footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid rgb(255, 209, 171);
}

@media (max-height:800px) {
    footer {
        position: static;
    }

    header {
        padding-top: 40px;
    }
}

.footer-distributed {
    background: linear-gradient(to bottom, rgb(43, 24, 21),rgb(43, 24, 21));
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 10px 50px 10px 50px;
    margin-top: 0px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
} */

/* Footer left */

/* .footer-distributed .footer-left {
    width: 30%;
}

.footer-distributed h3 {
    color: #ffffff;
    font: normal 36px 'Cookie', cursive;
    margin: 0;
}

.footer-distributed h3 span {
    color: rgb(255, 209, 171);
} */

/* Footer links */
/* 
.footer-distributed .footer-links {
    color: #ffffff;
    margin: 20px 0 12px;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
}

.footer-distributed .footer-company-name {
    color: rgb(255, 209, 171);
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

.footer-company-name {
    margin-left: 8% !important;
} */

/* Footer Center */
/* 
.footer-distributed .footer-center {
    width: 35%;
}

.footer-distributed .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
}

.footer-distributed .footer-center a {
    font-size: 17px;
    color: white;
    text-decoration: none;
}

.footer-distributed .footer-center i.fa-envelope {
    font-size: 17px;
    line-height: 38px;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin: 0;
    font-size: 17px;
    font-weight: 300;
}

.footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 17px;
    line-height: 2;
}

.footer-distributed .footer-center p a {
    color: rgb(255, 209, 171);
    text-decoration: none;
    ;
} */

/* Footer Right */
/* 
.footer-distributed .footer-right {
    width: 30%;
}

.footer-distributed .footer-company-about {
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-company-about span {
    display: block;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-distributed .footer-icons {
    margin-top: 25px;
}

.footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-right: 3px;
    margin-bottom: 5px;
}

.footer-distributed .footer-icons a:hover {
    background-color: rgb(255, 209, 171);
}

.footer-links a:hover {
    color: rgb(255, 209, 171);
}

.footer-links a {
    margin-right: 2%;
    margin-left: 2%;
}


@media (max-width: 600px) {
    footer {
        display: flex;
        flex-direction: column !important;
        border-top: 0px !important;
    }

    .footer-distributed .footer-left {
        display: flex !important;
        flex-direction: column;
        width: 100% !important;
        margin-bottom: 40px !important;
        text-align: center !important;
    }

    .footer-distributed .footer-left img {
        width: 60% !important;
        margin-left: 20%;
    }

    .footer-distributed .footer-center1,
    .footer-distributed .footer-right {
        display: block !important;
        width: 100% !important;
        margin-bottom: 40px !important;
        text-align: center !important;
    }

    .footer-distributed .footer-center1 i {
        margin-left: 0 !important;
    }

    .footer-center {
        display: none !important;
    }

    .footer-distributed .footer-center {
        width: 35%;
    }

    .footer-distributed .footer-center1 i {
        background-color: #33383b;
        color: #ffffff;
        font-size: 25px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        margin: 10px 15px;
        vertical-align: middle;
    }

    .footer-distributed .footer-center1 a {
        font-size: 17px;
        color: white;
        text-decoration: none;
    }

    .footer-distributed .footer-center1 i.fa-envelope {
        font-size: 17px;
        line-height: 38px;
    }

    .footer-distributed .footer-center1 p {
        display: inline-block;
        color: #ffffff;
        vertical-align: middle;
        margin: 0;
        font-weight: 300;
    }

    .footer-distributed .footer-center1 p span {
        display: block;
        font-weight: normal;
        font-size: 17px;
        line-height: 2;
    }

    .footer-distributed .footer-center1 p a {
        color: rgb(255, 209, 171);
        text-decoration: none;
        ;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    footer {
        display: flex;
        flex-direction: row !important;
        border-top: 0px !important;
    }

    .footer-distributed .footer-left {
        display: flex !important;
        flex-direction: column;
        width: 100% !important;
        margin-bottom: 35px !important;
        text-align: center !important;
        margin-left: -4%;
    }

    .footer-distributed .footer-left img {
        width: 60% !important;
        margin-left: 10%;
    }

    .footer-distributed .footer-center1,
    .footer-distributed .footer-right {
        display: block !important;
        width: 100% !important;
        margin-bottom: 40px !important;
        text-align: center !important;
    }

    .footer-distributed .footer-center1 i {
        margin-left: 0 !important;
    }

    .footer-center {
        display: none !important;
    }

    .footer-distributed .footer-center {
        width: 35%;
    }

    .footer-distributed .footer-center1 i {
        background-color: #33383b;
        color: #ffffff;
        font-size: 25px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        margin: 10px 15px;
        vertical-align: middle;
    }

    .footer-distributed .footer-center1 a {
        font-size: 17px;
        color: white;
        text-decoration: none;
    }

    .footer-distributed .footer-center1 i.fa-envelope {
        font-size: 17px;
        line-height: 38px;
    }

    .footer-distributed .footer-center1 p {
        display: inline-block;
        color: #ffffff;
        vertical-align: middle;
        margin: 0;
    }

    .footer-distributed .footer-center1 p span {
        display: block;
        font-weight: normal;
        font-size: 14px;
        line-height: 2;
    }

    .footer-distributed .footer-center1 p a {
        color: rgb(255, 209, 171);
        text-decoration: none;
        ;
    }

    .footer-right .footer-company-about {
        margin-right: -4%;
        align-items: justify;
    }
} */