@media (max-width: 768px) {
    .login-container {
      padding: 10px; /* Reduce padding on smaller screens */
    }
  
    h1 {
      font-size: 24px; /* Adjust heading size */
    }
  
    input, select {
      font-size: 14px; /* Adjust input font size */
    }
  
    button {
      font-size: 14px; /* Adjust button font size */
    }
  
    .links {
      font-size: 14px; /* Adjust link font size */
    }
    .inputfield{
        width: 80% !important;
        height:6vh !important;
    }
.containerstyle{
    width:75% !important;
}
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 20px; /* Further adjust heading size for very small screens */
    }
  
    input, select, button {
      font-size: 10px; /* Further adjust font size */
      padding: 5px; /* Reduce padding */
    }
  
    .links {
      font-size: 10px; /* Adjust link font size */
    }
    /* .inputfield{
        height: 3vh !important;
        width: 100% !important;
    } */
  }
  @media (max-width: 480px) {
    .inputfield{
      width: 100% !important;
      height: 5vh !important;
  }
.containerstyle{
  width:80% !important;
}
  } 
 
  .links:hover{
    color: #700815 !important;
    transform: scale(1.02);
}
.login-button {
    margin: 2px !important;
    transition: 0.2s;
}

@media screen and (max-width: 768px) {
    .main-container-login{
        width: 96vw !important;
    }
}

input {
    width: 50%;
    border: none;
    font-size: 3vh;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: black;
    margin-left: 15px;
}

/* For WebKit browsers (Chrome, Edge) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    background-color: transparent !important;
    color: black !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
}

/* For Firefox */
input:-moz-autofill {
    background-color: transparent !important;
    color: black !important;
}
input:focus {
    outline: none; /* Remove the outline when focused */
}