@font-face {
    font-family: 'Japan Daisuki Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Japan Daisuki Regular'), url('../../assets/fonts/JapanDaisuki-8OeaZ.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Katsumi';
    src: url('../../assets/fonts/KATSUMI.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

.schedule_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    width: 100vw;
    background-image: url('./photos24/sched.jpg');
    background-size: 100% 100%;
    overflow-x: hidden;
    background-repeat: no-repeat;
    overflow-x: hidden;
    font-family: 'Sylfaen', sans-serif;
    padding: 50px 0;

}

.schedule_upper {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Japan Daisuki Regular', sans-serif;
    font-weight: bolder;
    font-size: 400%;
    color: white;
    -webkit-text-stroke: 1px #700815;
    text-shadow: 2px 2px 6px rgba(0,0,0,0.5);
    padding-top: 2%;
    margin-bottom: 10%;
}

.schedule_middle {
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 17%;
    align-items: center;
    font-size: 250%;
    color: white;
    margin-bottom: 5%;
    margin-top: 4%;
}

.schedule_lower {
    height: fit-content;
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    gap: 30px;

    color: white;
    /* background-color: rgba(255, 0, 217, 0.273); */
}

.schedule_lower1 {
    height: 40vh;
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    color: white;
    /* background-color: rgba(255, 0, 217, 0.273); */
}

.schedule_item {
    height: 50px;
    width: 70%;

    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 20px;

}

.schedule_event {
    height: 100%;
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2%;
    color: black;
    font-size: 20px;
}

.schedule_time {
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 20px;

}

.schedule_event1 {
    height: 100%;
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 3%;

    font-size: 150%;
}

.schedule_time1 {
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 150%;

}

.schedule_item1 {
    height: 7%;
    width: 70%;
    /* background-color: rgba(255, 255, 255, 0.8); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 20px;
}

@media screen and (min-width: 786px) and (max-width: 1280px) {
    .schedule_upper {
        justify-content: flex-start;
        padding-left: 15.5%;
    }
}


@media screen and (max-width: 820px) {
    .schedule_time {
        font-size: 100%;
        width: 40%;
    }

    .schedule_event {
        font-size: 100%;
        width: 60%;
    }
}

@media screen and (max-width: 500px) {
    .schedule_time {
        font-size: 80%;
        width: 45%;
    }

    .schedule_event {
        font-size: 80%;
        width: 55%;
    }

    .schedule_upper {
        font-size: 300%;
    }
}

.schedule_event {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.schedule_item {
    height: 50vh;
}

.schedule_container {
    height: 100vh;
}

.typewriter {
    font-family: 'Katsumi', sans-serif;
    font-weight: bolder;
    font-size: 150%;
    color: #700815;
}

.typewriter h1 {
    overflow: hidden;
    /* Ensures the content is not revealed until the animation */
    border-right: .15em solid #700815;
    /* The typwriter cursor */
    white-space: nowrap;
    /* Keeps the content on a single line */
    margin: 0 auto;
    /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em;
    /* Adjust as needed */
    animation:
        typing 3.5s steps(30, end),
        blink-caret .5s step-end infinite;
}