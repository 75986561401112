@font-face {
  font-family: 'Japan Daisuki Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Japan Daisuki Regular'), url('../../assets/fonts/JapanDaisuki-8OeaZ.woff') format('woff');
}

@font-face {
  font-family: 'Katsumi';
  src: url('../../assets/fonts/KATSUMI.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sylfaen';
  src: url('../../assets/fonts/sylfaen.woff') format('woff');
}


.newhome {
    overflow-x: hidden;
    position: relative; /* To position the clouds and layers correctly */
    overflow-y: hidden;
}












.falling-leaves {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100%;
    /* image is only 880x880 */
    max-width: 880px;    
    max-height: 880px;
    transform: translate(-50%, 0);
    z-index: 100;
    /* border: 20px solid #fff;
    border-radius: 50px;
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/125707/sidebar-bg.png) no-repeat center center;
    background-size: cover;
    overflow: hidden; */
  }
  
  .leaf-scene {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      transform-style: preserve-3d;
  
      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/125707/leaf.svg) no-repeat;
        background-size: 100%;
        transform-style: preserve-3d;
        backface-visibility: visible;
      }
    }




















/* Main Home styles */
.mainHome{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
}

.upperMainHome{width: 100%; height: 70%;
/* background-color: rgba(0, 255, 255, 0.323); */
display: flex;
justify-content: flex-end;
align-items: center;
flex-direction: column;
margin-bottom: 10%;
}

.headingHome{
    height: 30%;
    width: 100%;
    /* background-color: rgba(0, 255, 255, 0.323); */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 5%;
}

.headingHome img{
    height: 420% ;
}

.headingHome .noren{
    font-size: 220%;
    color: #700815;
    font-weight: 600;
    padding-right: 2.7%;
    margin-top: -13px;
    font-family: 'Sylfaen', sans-serif;
}

@media screen and (max-width: 1286px) {
    .noren{
        font-size: 200%;
        color: #700815;
        font-weight: 600;
        padding-right: 2.7%;
    }
    .headingHome img{
        height: 400% ;
    }
}

@media screen and (max-width: 768px) {
    .headingHome{
        margin-bottom: 10%;
    }
    .headingHome .noren{
        font-size: 170%;
        font-weight: 600;
        padding-right: 4.3%;
    }
    .headingHome img{
        height: 400% ;
    }
}

@media screen and (max-width: 660px) {
    .headingHome{
        margin-bottom: 15%;
        margin-left: 11%;
    }
    .headingHome img{
        height: 400% ;
    }
}

@media screen and (max-width: 600px) {
    .headingHome{
        margin-bottom: 20%;
        align-items: center;
        margin-right: -12%;
        margin-left: 0;
    }
    .headingHome .noren{
        font-size: 170%;
        font-weight: 600;
        padding-right: 5.8%;
    }
    .headingHome img{
        height: 400% ;
    }
}
.lowerMainHome{
    width: 100%;
    height: 30%;
    /* background-color: rgba(153, 255, 0, 0.374); */
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

.registerHome{
    height: 85%;
  width: 30%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 255, 255, 0.323);  */
  margin-top: -5%;
  background-image: url('./photos24/register.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  font-family: 'Japan Daisuki Regular', sans-serif;
  font-weight: bold;
  font-size: 35px;
  color: #700815;
  z-index: 100;
  /* padding-top: 15px; */

    }


    @media screen and (max-width: 1050px) {
        .registerHome{
            height: 75%;
        }
    }

    @media screen and (max-width: 940px) {
        .registerHome{
            height: 40%;
        width: 40%;

        }
    }

    @media screen and (max-width: 821px) {
      /* .registerHome{

        padding-bottom: 5%;

      } */
  }

    @media screen and (max-width: 768px) {
      .registerHome{
        width: 100%;
        /* height: 35%; */
    /* padding-top: 3%; */
        max-width: 270px;
        margin-top: -10%;
        height: 35%;
        background-size: contain;
      }
      .realistic-flame{
        display: none;
    }
      
    }

    @media screen and (max-width: 400px) {
        .registerHome{
            height: 80px;
            width: 200px;
            padding-bottom: 7%;

        }
    }
    

.dragonHome{
    height: 60%;
    width: 20%;
    /* background-color: rgba(0, 255, 255, 0.323); */
    position: absolute;
    right: 0%;
    top: 2%;

}




/* Realistic Flame Effect (left side) */
.realistic-flame {
    position: absolute;
    top: 71%;
    left: 25%;
    width: 50px;
    height: 100px;
    background: radial-gradient(circle, rgba(255, 119, 0, 0.8) 20%, transparent 70%);
    animation: flicker 0.5s infinite alternate linear;
    filter: blur(2px);
}

@keyframes flicker {
    0% {
        transform: scale(1);
        opacity: 0.9;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }
    100% {
        transform: scale(1.2);
        opacity: 0.5;
    }
}

@media screen and (max-width: 1286px) {

    .realistic-flame {
        top: 58%;
    }
}

@media screen and (max-width: 1275px) {

    .realistic-flame {
        top: 56%;
    }
}

@media screen and (max-width: 1255px) {

    .realistic-flame {
        top: 55%;
    }
}

@media screen and (max-width: 1230px) {

    .realistic-flame {
        top: 54%;
    }
}

@media screen and (max-width: 1205px) {

    .realistic-flame {
        top: 53%;
    }
}

@media screen and (max-width: 1185px) {

    .realistic-flame {
        top: 52%;
    }
}

@media screen and (max-width: 1160px) {

    .realistic-flame {
        top: 50%;
        left: 23%;
    }
}

@media screen and (max-width: 1118px) {

    .realistic-flame {
        top: 48.5%;
        left: 23%;
    }
}

@media screen and (max-width: 1070px) {

    .realistic-flame {
        top: 47%;
        left: 23%;
    }
}

@media screen and (max-width: 850px) {

    .realistic-flame {
       display: none;
    }
}




/* Semicircle Hover Effect for Moon (right side) */
.semicircle-moon1 {
    position: absolute;
    top: 86%;
    right: 2.5%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    transform: rotate(72deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */
}



.semicircle-moon2 {
    position: absolute;
    top: 80%;
    right: 2.8%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    transform: rotate(57deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */
}



.semicircle-moon3 {
    position: absolute;
    top: 72%;
    right: 2.4%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    transform: rotate(50deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */
}




.semicircle-moon4 {
    position: absolute;
    top: 65%;
    right: 3%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    transform: rotate(40deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */
}



.semicircle-moon5 {
    position: absolute;
    top: 57%;
    right: 3.4%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    transform: rotate(38deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */
}


.semicircle-moon6 {
    position: absolute;
    top: 50%;
    right: 4.5%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    transform: rotate(30deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */
}




.semicircle-moon7 {
    position: absolute;
    top: 43%;
    right: 5.8%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    transform: rotate(25deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */
}

.semicircle-moon8 {
    position: absolute;
    top: 35%;
    right:7.9%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    transform: rotate(20deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */}

.semicircle-moon9 {
    position: absolute;
    top: 30%;
    right:10%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    transform: rotate(10deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */}
    

    .semicircle-moon10{
        position: absolute;
        top: 25%;
        right:12.8%;
        width: 300px;
        height: 150px; /* Larger size */
        background-color: transparent;
        border-top-left-radius: 300px;
        border-top-right-radius: 300px;
        transform: rotate(2deg); /* Rotate 45% clockwise */
        overflow: hidden;
        transition: all 0.3s ease; /* Smoother transition */}

     
.semicircle-moon11{
    position: absolute;
    top: 20%;
    right:16%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    transform: rotate(-5deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */
}      

.semicircle-moon12{
    position: absolute;
    top: 17%;
    right:19%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    transform: rotate(-10deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */
}

.semicircle-moon13{
    position: absolute;
    top: 14.8%;
    right:22%;
    width: 300px;
    height: 150px; /* Larger size */
    background-color: transparent;
    border-top-left-radius: 300px;  
    border-top-right-radius: 300px;
    transform: rotate(-20deg); /* Rotate 45% clockwise */
    overflow: hidden;
    transition: all 0.3s ease; /* Smoother transition */}


.semicircle-moon1::before,.semicircle-moon2::before,.semicircle-moon3::before,.semicircle-moon4::before,.semicircle-moon5::before,.semicircle-moon6::before,.semicircle-moon7::before,.semicircle-moon8::before,.semicircle-moon9::before ,.semicircle-moon10::before ,.semicircle-moon11::before ,.semicircle-moon12::before,.semicircle-moon13::before {
    content: '';
    position: absolute;
    width: 150px;
    height: 150px; /* Adjusted to cover1top part */
    top: -75px; /* Ensure only top part shows */
    right: -75px;
    /* background: rgba(255, 255, 255, 0.3);  */
    /* background: rgba(108, 15, 15, 0.46);  */

    border-top-right-radius: 50%;
    transition: all 0.3s ease;
}

.semicircle-moon1:hover::before,
.semicircle-moon2:hover::before,.semicircle-moon3:hover::before,.semicircle-moon4:hover::before,.semicircle-moon5:hover::before,.semicircle-moon6:hover::before,.semicircle-moon7:hover::before,.semicircle-moon8:hover::before ,.semicircle-moon9:hover::before,.semicircle-moon10:hover::before ,.semicircle-moon11:hover::before ,.semicircle-moon12:hover::before ,.semicircle-moon13:hover::before {
    background: rgba(244, 221, 160, 0.9); /* Much brighter on hover */
    box-shadow: 0 0 40px 30px rgba(244, 221, 160, 0.9); /* Stronger glow */
}



@media screen and (max-width: 1287px) {
    
    .semicircle-moon1,.semicircle-moon2{
        display: none;
    }

    .semicircle-moon3{
        top: 66%;
        right: 1.8%;
        transform: rotate(65deg);
    }
    .semicircle-moon4{
        top: 60%;
        right: 1.9%;
        transform: rotate(55deg);
    }    .semicircle-moon5{
        top: 53%;
        right: 1.9%;
        transform: rotate(50deg);
    }    .semicircle-moon6{
        top: 46%;
        right: 2.5%;
        transform: rotate(42deg);
    }    .semicircle-moon7{
        top: 40%;
        right: 3.7%;
        transform: rotate(34deg);
    }    .semicircle-moon8{
        top: 33%;
        right: 5.4%;
        transform: rotate(27deg);
    }    .semicircle-moon9{
        top: 27%;
        right: 7.9%;
        transform: rotate(18deg);
    }    .semicircle-moon10{
        top: 22.4%;
        right: 10.4%;
        transform: rotate(10deg);
    }    .semicircle-moon11{
        top: 18.1%;
        right: 13.8%;
        transform: rotate(-1deg);
    }
    .semicircle-moon12{
        top: 15%;
        right: 16%;
        transform: rotate(-10deg);
    } .semicircle-moon13{
        top: 13.3%;
        right: 19.4%;
        transform: rotate(-14deg);
    }
}



@media screen and (max-width: 1200px) {
    
    .semicircle-moon1,.semicircle-moon2{
        display: none;
    }

    .semicircle-moon3{
        top: 66%;
        right: 2.4%;
        transform: rotate(70deg);
    }
    .semicircle-moon4{
        top: 60%;
        right: 2.02%;
        transform: rotate(60deg);
    }    .semicircle-moon5{
        top: 53%;
        right: 2%;
        transform: rotate(50deg);
    }    .semicircle-moon6{
        top: 46%;
        right: 2.2%;
        transform: rotate(42deg);
    }    .semicircle-moon7{
        top: 40%;
        right: 3%;
        transform: rotate(34deg);
    }    .semicircle-moon8{
        top: 33%;
        right: 4.6%;
        transform: rotate(27deg);
    }    .semicircle-moon9{
        top: 27%;
        right: 6.9%;
        transform: rotate(18deg);
    }    .semicircle-moon10{
        top: 22.4%;
        right: 9.3%;
        transform: rotate(10deg);
    }    .semicircle-moon11{
        top: 18.1%;
        right: 12.7%;
        transform: rotate(-1deg);
    }
    .semicircle-moon12{
        top: 15%;
        right: 16%;
        transform: rotate(-10deg);
    } .semicircle-moon13{
        top: 13.3%;
        right: 16%;
        transform: rotate(-10deg);
    }
}

@media screen and (max-width: 786px) {
  .semicircle-moon1,.semicircle-moon2,.semicircle-moon3,.semicircle-moon4,.semicircle-moon5,.semicircle-moon6,.semicircle-moon7,.semicircle-moon8,.semicircle-moon9,.semicircle-moon10,.semicircle-moon11,.semicircle-moon12,.semicircle-moon13{
    display: none;
    
  }

}

h1 {
    color: white;
}

/* Add zigzag effect to Layer 2 */
.layer2 {
    height: 150vh;
    /* clip-path: polygon(0 0, 10% 20%, 20% 0, 30% 20%, 40% 0,10% 20%, 20% 0, 30% 20%, 40% 0,10% 20%, 20% 0, 30% 20%, 40% 0,10% 20%, 20% 0, 30% 20%, 40% 0, 50% 20%, 60% 0, 70% 20%, 80% 0, 100% 20%, 100% 100%, 0 100%); */
    position: relative;
    /* overflow: auto; */
    z-index: 1000; /* Ensure this layer is beneath Layer 1 */
    background-image: url('./photos24/layer2.png'); /* Use the image directly in CSS */
    background-size: cover; /* Ensure the image covers the entire area */
    background-repeat: no-repeat; /* Prevents image from repeating */
}

/* Clouds */
.clouds1 {
    position: absolute;
    top: 0%; /* Position clouds above Layer 2 */
    width: 100%;
    height: 100% ; /* Give a height to ensure clouds are visible */
    overflow: hidden; /* Prevents clouds from overflowing the container */
    pointer-events: none; /* Ensure no interaction with clouds */
    z-index: 4; /* Ensure clouds are on top of Layer 2 */
    overflow-y: hidden;
    top: 13%;
    opacity: 0.7;
}
.clouds1 img {
  height: 10%;
  opacity: 2; 
}

.layer3 {
  height: 170vh;
}

@media screen and (max-width: 1025px) {
  .clouds1 img {
    height: 6%;
    opacity: 2; 
  }

  .layer2 {
    height: 80vh;
  }

  .layer3 {
    height: 100vh;
  }
}

.clouds2 {
  position: absolute;
  top: 0%; /* Position clouds above Layer 2 */
  width: 100%;
  height: 100%; /* Give a height to ensure clouds are visible */
  overflow: hidden; /* Prevents clouds from overflowing the container */
  pointer-events: none; /* Ensure no interaction with clouds */
  z-index: 4; /* Ensure clouds are on top of Layer 2 */
  overflow-y: hidden;
  top: 32%;
  opacity: 0.7;

}

.clouds3 {
  position: absolute;
  top: 52%; /* Position clouds above Layer 2 */
  width: 100%;
  height: 100%; /* Give a height to ensure clouds are visible */
  overflow: hidden; /* Prevents clouds from overflowing the container */
  pointer-events: none; /* Ensure no interaction with clouds */
  z-index: 4; /* Ensure clouds are on top of Layer 2 */
  overflow-y: hidden;
  opacity: 0.8;

}

.clouds4 {
  position: absolute;
  top: 18%; /* Position clouds above Layer 2 */
  width: 100%;
  height: 100%; /* Give a height to ensure clouds are visible */
  overflow: hidden; /* Prevents clouds from overflowing the container */
  pointer-events: none; /* Ensure no interaction with clouds */
  z-index: 4; /* Ensure clouds are on top of Layer 2 */
  overflow-y: hidden;
  opacity: 1;
}

/* 
@media screen and (min-width: 1286px) {
    .clouds1{
        top: 7%;
      }
    
      .clouds2{
        top: 25%;
      }
    
      .clouds3{
        top: 45%;
      }
    
      .clouds4{
        top: 80%; 
      }
      
}

@media screen and (max-width: 1286px) {
    .clouds1{
        top: 7%;
      }
    
      .clouds2{
        top: 25%;
      }
    
      .clouds3{
        top: 45%;
      }
    
      .clouds4{
        top: 79%; 
      }
      
}

@media screen and (max-width: 1025px) {
    .clouds1{
        top: 5.5%;
      }
    
      .clouds2{
        top: 22%;
      }
    
      .clouds3{
        top: 38%;
      }
    
      .clouds4{
        top: 82.5%; 
      }
      
}

@media screen and (max-width: 970px) {
    .clouds1{
        top: 6%;
      }
    
      .clouds2{
        top: 23%;
      }
    
      .clouds3{
        top: 38.5%;
      }
    
      .clouds4{
        top: 83.5%; 
      }
      
}

@media screen and (max-width: 885px) {
    .clouds1{
        top: 6.5%;
      }
    }

@media screen and (max-width: 786px) {
  .clouds1{
    top: 7%;
  }

  .clouds2{
    top: 24%;
  }

  .clouds3{
    top: 40%;
  }

  .clouds4{
    top: 84.5%; 
  }
  
}

@media screen and (max-width: 700px) {
    .clouds1{
      top: 8%;
    }
  
    .clouds2{
      top: 24%;
    }
  
    .clouds3{
      top: 40%;
    }
  
    .clouds4{
      top: 85.5%; 
    }
    
  }

  @media screen and (max-width: 640px) {
    .clouds1{
      top: 6.3%;
    }
  
    .clouds2{
      top: 19%;
    }
  
    .clouds3{
      top: 31.5%;
    }
  
    .clouds4{
      top: 88.5%; 
    }
    
  }


  @media screen and (max-width: 607px) {
  
    .clouds4{
      top: 88.7%; 
    }
    
  }

  @media screen and (max-width: 550px) {
    .clouds1{
      top: 6.5%;
    }
  
    .clouds2{
      top: 19%;
    }
  
    .clouds3{
      top: 31.5%;
    }
  
    .clouds4{
      top: 89.5%; 
    }
    
  }


  @media screen and (max-width: 500px) {
    .clouds1{
      top: 6.8%;
    }
  
    .clouds2{
      top: 19%;
    }
  
    .clouds3{
      top: 31.5%;
    }
  
    .clouds4{
      top: 89.5%; 
    }
    
  }

  @media screen and (max-width: 460px) {
    .clouds1{
      top: 7%;
    }
  
    .clouds2{
      top: 19.5%;
    }
  
    .clouds3{
      top: 31.9%;
    }
  
    .clouds4{
      top: 89.8%; 
    }
    
  }

  
  @media screen and (max-width: 432px) {
    .clouds1{
      top: 7.9%;
    }
  
    .clouds2{
      top: 21.5%;
    }
  
    .clouds3{
      top: 35%;
    }
  
    .clouds4{
      top: 89.5%; 
    }
    
  }

  @media screen and (max-width: 417px) {
    .clouds1{
      top: 7.6%;
    }
  
    .clouds2{
      top: 20.5%;
    }
  
    .clouds3{
      top: 33.5%;
    }
  
    .clouds4{
      top: 90%; 
    }
    
  }

  @media screen and (max-width: 378px) {
    .clouds1{
      top: 6.6%;
    }
  
    .clouds2{
      top: 17.8%;
    }
  
    .clouds3{
      top: 29%;
    }
  
    .clouds4{
      top: 90.7%; 
    }
    
  } */
  
.cloud {
    position: absolute;
    animation: moveClouds 20s linear infinite; /* Adjust duration for speed */
    opacity: 1; /* Slight transparency for a dreamy effect */
}

/* Position clouds at specific locations */
.cloud:nth-child(1) {
    left: 0%;
    animation-delay: 0s;
}

.cloud:nth-child(2) {
    left: 40%;
    animation-delay: -5s; /* Slightly stagger the animations */
}

.cloud:nth-child(3) {
    left: 80%;
    animation-delay: -10s; /* Slightly stagger the animations */
}

@keyframes moveClouds {
    0% {
        transform: translateX(-100%); /* Start from the left */
    }
    100% {
        transform: translateX(100%); /* Move clouds across the screen */
    }
}

/* Realistic Flame Effect */


@keyframes flicker {
    0% {
        transform: scale(1);
        opacity: 0.9;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }
    100% {
        transform: scale(1.2);
        opacity: 0.5;
    }
}

/* About Us Card */
.About {
    /* height: 100%;
    width: 100%; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-top: 800px; */
    text-align: center;
    font-size: 40px;
    color:#700815;
    margin: 30vh;
    margin-left: 15%;
    margin-right: 15%;
    z-index: 500;
    position: relative;   
    /* height: 100vh; */
}

.About_Heading{
    font-family: 'Japan Daisuki Regular', sans-serif;
    font-weight: bolder;
    font-size: 100px;
    color: white;
    -webkit-text-stroke: 1px #700815;
    text-shadow: 2px 2px 6px rgba(0,0,0,0.5);
}

.About_Paragraph{
    font-family: 'Katsumi', sans-serif;
    font-weight: bolder;
    font-size: 150%;
    color: #700815;
    margin-bottom: 150vh;
}
/* Home1.css */
.mute-button {
    cursor: pointer;
    outline: none;
}

/*  */


/* About Us Styles */
.about-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.about-box {
    border: 2px solid #8B4513; /* Brownish border */
    padding: 20px;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for depth */
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
}

.about-box h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
    font-family: 'Japan Daisuki Regular', sans-serif;
}

.about-scroll {
    max-height: 150px; /* Limit the height of the scrolling text */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 10px;
}

.scrolling-text {
    font-size: 1.2rem;
    color: #333;
    line-height: 1.5;
}



/* Move the dragon and register down when Layer 2 is visible */
.moveDown .dragonHome, 
.moveDown .registerHome {
    transform: translateY(100px); /* Move down */
    transition: transform 0.5s ease;
}

/* Scroll snap effect */
.newhome {
    scroll-snap-type: y mandatory; /* Snap to each section */
}

.layer1, .layer2, .layer3 {
    scroll-snap-align: start; /* Snap to start */
    padding: 0;
}

/* General Styles for Dragon, Register, and Clouds */
.dragonHome {
    position: absolute;
    right: 10%;
    top: 10%;
    transition: transform 0.5s ease;
}

/* .registerHome {
    font-size: 24px;
    background: #ffcc00;
    padding: 10px 20px;
    border-radius: 5px;
    transition: transform 0.5s ease;
} */

/* Cloud animations */
.cloud {
    position: absolute;
    opacity: 0.8;
    animation: moveClouds 30s linear infinite;
}

@keyframes moveClouds {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(100%);
    }
}

@media screen and (max-width: 1080px) {
    .About p{
        font-size: 35px;
      }

      .About_Heading p {
        font-size: 72px;
      }
}

@media screen and (max-width: 768px) {
  .About p{
    font-size: 28px;
  }



}

@media screen and (max-width: 563px) {
    .About p{
        font-size: 23px;
      }

      .About_Heading p {
        font-size: 54px;
      }
}

@media screen and (max-width: 430px) {
    .About p{
        font-size: 18px;
      }

    .About_Heading p {
      font-size: 42px;
    }
}

.layer2, .layer3, .layer4, .layer5 {
    will-change: transform; /* Helps optimize rendering */
  }

@media screen and (max-width:1130px) {
    /* .realistic-flame{
        display: none;
    } */
    .semicircle-moon1,.semicircle-moon2,.semicircle-moon3,.semicircle-moon4,.semicircle-moon5,.semicircle-moon6,.semicircle-moon7,.semicircle-moon8,.semicircle-moon9,.semicircle-moon10,.semicircle-moon11,.semicircle-moon12,.semicircle-moon13{
        display: none;
    }
}

.clouds1always{
    position: absolute;
    top: 10.5%;
    left: -9%;
    width: 100%;
    /* height: 100%; */

    z-index: 10;
}

.clouds2always{
    position: absolute;
    top: 10.5%;
    left: 30%;
    width: 100%;
    /* height: 100%; */

    z-index: 10;
}
.clouds3always{
    position: absolute;
    top: 10.5%;
    left: 55%;
    width: 100%;
    /* height: 100%; */

    z-index: 10;
}

@media screen and (max-width: 1286px) {
.clouds1always{
  top: 9.1%;

}
.clouds2always{
  top: 9.1%;

}.clouds3always{
  top: 9.1%;

}
}

@media screen and (max-width: 1025px) {
  .clouds1always,.clouds2always,.clouds3always{
    display: none;

  }
}

@media screen and (max-width: 786px) {
  .clouds1always,.clouds2always,.clouds3always{
    top: 12%;
    display: block;

  }
}

@media screen and (max-width: 640px) {
  .clouds1always,.clouds2always,.clouds3always{
    top: 6.5%;

  }
}

@media screen and (max-width: 575px) {
  .clouds1always,.clouds2always,.clouds3always{
    top: 7.2%;

  }
}

@media screen and (max-width: 500px) {
  .clouds1always,.clouds2always,.clouds3always{
    top: 6.6%;

  }
}

@media screen and (max-width: 431px) {
  .clouds1always,.clouds2always,.clouds3always{
    top: 9%;

  }

  .layer3 {
    height: 120vh;
  }
}

@media screen and (max-width: 415px) {
  .clouds1always,.clouds2always,.clouds3always{
    top: 9%;
  }

  
}
@media screen and (max-width: 400px) {
  .clouds1always,.clouds2always,.clouds3always{
    top: 8.6%;
  }

  
}
@media screen and (max-width: 376px) {
  .clouds1always,.clouds2always,.clouds3always{
    top: 7.3%;
  }

  .layer2 {
    height: 100vh;
  }

  .layer3 {
    height: 140vh;
  }
}

