.paragraph{
    display: flex;
    font-size: 40px;
    flex-wrap: wrap;
    .word{
      position: relative;
      margin-right: 12px;
      margin-top: 12px;
      .shadow{
        position: absolute;
        opacity: 20%;
      }
    }
    
  }