.auth-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url('./loginbg.png'); Background image */
    background-size: cover;
    background-position: center;
}

/* .form-container_login {
    width: 55%;
    padding: 30px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    transition: transform 0.6s ease-in-out;
} */
.slide-in-right {
    animation: slideInRight 2s forwards;
}

.slide-in-left {
    animation: slideInLeft 2s forwards;
}

@keyframes slideInRight {
    from {
        transform: translateX(5%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(0%);
        opacity: 0;
    }
    to {
        transform: translateX(5%);
        opacity: 1;
    }
}
@media (max-width:480px){
    @keyframes slideInLeft {
        from {
            transform: translateX(0%);
            opacity: 0;
        }
        to {
            transform: translateX(0%);
            opacity: 1;
        }
    }
    @keyframes slideInRight {
        from {
            transform: translateX(0%);
            opacity: 0;
        }
        to {
            transform: translateX(0%);
            opacity: 1;
        }
    }
}