@import url('https://fonts.googleapis.com/css2?family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Kadwa:wght@400;700&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');

.wobble-hor-top {
  -webkit-animation: wobble-hor-top 2.8s infinite both;
  animation: wobble-hor-top 2.8s infinite both;
}

@-webkit-keyframes wobble-hor-top {

  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
    transform: translateX(-30px) rotate(6deg);
  }

  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
    transform: translateX(15px) rotate(-6deg);
  }

  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
    transform: translateX(-15px) rotate(3.6deg);
  }

  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
    transform: translateX(9px) rotate(-2.4deg);
  }

  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
    transform: translateX(-6px) rotate(1.2deg);
  }
}

@keyframes wobble-hor-top {

  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
    transform: translateX(-30px) rotate(6deg);
  }

  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
    transform: translateX(15px) rotate(-6deg);
  }

  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
    transform: translateX(-15px) rotate(3.6deg);
  }

  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
    transform: translateX(9px) rotate(-2.4deg);
  }

  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
    transform: translateX(-6px) rotate(1.2deg);
  }
}

/* Add your styles here */

.event-list-container {
  background-image: url("./photos24/image\ 5.png");
  background-size: cover;
  background-repeat: repeat-y;
  background-position: center;
  overflow: scroll;
  min-height: 100vh;
  padding: 23vh 20%;
  /* padding-bottom: 20vh; */
}

.page-content {
  padding: 7vw 10vw;
}

.event-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 30vh;
  overflow-x: hidden;
  /* background-color: rgba(81, 86, 145, 1); */
}

.Event_Heading {
  font-family: 'Japan Daisuki Regular', sans-serif;
  font-weight: bolder;
  font-size: 80px;
  color: white;
  -webkit-text-stroke: 1px #700815;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 5vh;
}

@media screen and (max-width: 1024px) {
  .event-image a {
    left: 3% !important;
  }

  .Event_Heading {
    margin-top: 8vh;
  }
}


@media screen and (max-width: 770px) {
  .event-list-item {
    display: flex;
    flex-direction: row !important;

    width: 70vw !important;

    /* background-color: #515691E5; */
    opacity: 0.9;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 30vh;
  }

  .event-image img {
    /* background-image: url('./photos24/Group\ 30.png');
    background-size: cover;
    margin: 0;
    margin-bottom: 20px;*/
    /* width: 50px !important; */
    /* height: 50px !important; */
    /* padding: 0 !important;  */
    /* width: 250px !important;
    height: 100px !important; */
    width: 250px !important;
    /* height: 250px !important; */
    /* border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important; */

    border-radius: 20px 20px 0 0 !important;

  }

  .event-info {
    width: 250px !important;
    /* height: 200px !important; */
    padding: 0 !important;
    border-top: 0px !important;
    border-left: 10px solid white !important;
    border-right: 10px solid white !important;
    border-bottom: 10px solid white !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
}

.coming_soon {
  background-color: #515691E5;
  border: 10px solid white;
  flex: 3;
  opacity: 90%;
  /* padding-top: 20px; */
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Japan Daisuki Regular', sans-serif;
  font-size: 4rem;
  color: white;
  font-weight: 100;
  /* height: 200px; */
  /* min-height: 25vh; */
  /* height: fit-content; */
}

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid #700815; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(30, end),
    blink-caret .5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white }
}

.event-image {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.event-image img {
  width: 300px !important;
  height: 300px;
}

.event-info {
  background-color: #515691E5;
  border: 10px solid white;
  flex: 3;
  opacity: 90%;
  padding-top: 20px;
  /* height: 200px; */
  /* min-height: 25vh; */
  height: fit-content;
}

.event-image a {
  transition: scale 0.3s ease;
}

.event-image:hover {
  a {
    scale: 1.2 !important;
  }
}

.border-left {
  border-left: 0px;
}

.border-right {
  border-right: 0px;
}

.event-title {
  /* height: 50px; */
  /* background-color: #515691E5; */
  /* color: #fff; */
  /* padding: 10px; */
  /* margin-bottom: 0; */
  /* border-radius: 20px 20px 0 0; */
  display: flex;
  font-size: 2em;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Kadwa", serif;
}

/* Remove margin between title and description */

.event-description {
  font-size: 1em;
  font-family: "Sansita", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 250px; */
  background-color: #515691E5;
  text-align: center;
  padding: 0 10px;
  color: #fff;
}

/* Change text color to white */

.top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Add padding as needed */
  /* Background color for the top section */
  color: #fff;
  margin-top: -50px;
  /* Text color for the title */
}

@media screen and (max-width: 768px) {
  .top-section {
    margin-top: 0;
    margin-bottom: 8vh;
  }

  .top-imagee {
    margin-top: 20px;
    width: 80vw !important;
  }

  .main-event {
    display: flex;
    flex-direction: column;
    width: 150%;
  }

  .poster {
    width: '100%' !important;
  }

  .event-image a {
    left: '2%';
    opacity: 1
  }

  /* .event-desc {
    width: 80vw !important;
    margin-bottom: 20vh;
  } */

  .Event_Heading {
    font-size: 35px;
  }
}

/* Style for the top image */
.top-image {
  width: 100%;
  /* Make the image responsive */
  max-width: 300px;
  /* Set a maximum width for the image */
  height: auto;
}

/* Style for the title on top of the image */
.top-title {
  font-size: 34px;
  /* Adjust the font size as needed */
  margin-top: -150px;
  /* Add margin as needed */
  font-family: "Inknut Antiqua", serif;
  color: #ffd1ab;
  margin-bottom: 50px;
}

/* Style for the top image */
.top-imagee {
  width: 100%;
  /* Make the image responsive */
  /* Set a maximum width for the image */
  height: auto;
  margin-top: 6vh;
  width: 27vw;
}

/* Style for the title on top of the image */
.top-titlee {
  font-size: 34px;
  /* Adjust the font size as needed */
  /* Add margin as needed */
  font-family: "Inknut Antiqua", serif;
  color: #ffd1ab;
  margin-top: -13vh;
  margin-bottom: 3vh;
}

/* .register-button {
  background-color: #3f47a0;
  color: #fff;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;

}

.register-button:hover {
  background-color: #392828;
} */
.register-button {
  float: right;
  margin-top: 1vh;
  padding: 0.3rem 0.6rem;
  width: 100%;
  background-color: #3f47a0;
  border: 2px white solid;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.49em;
  opacity: 0.7;
  cursor: not-allowed;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Smooth transition */
}

.register-button.enabled {
  opacity: 1;
  /* Full opacity when the button is enabled */
  cursor: pointer;
}

.register-button.enabled:hover {
  background-color: white;
  color: #3f47a0;
}

@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

/* shiny button css start */

@import url("https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,500&display=swap");

:root {
  --shiny-cta-bg: rgb(0, 0, 0);
  --shiny-cta-bg-subtle: #1a1818;
  --shiny-cta-fg: white;
  --shiny-cta-highlight: blue;
  --shiny-cta-highlight-subtle: #8484ff;
}

@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@property --gradient-angle-offset {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@property --gradient-percent {
  syntax: "<percentage>";
  initial-value: 5%;
  inherits: false;
}

@property --gradient-shine {
  syntax: "<color>";
  initial-value: white;
  inherits: false;
}

.shiny-cta {
  --animation: gradient-angle linear infinite;
  --duration: 3s;
  --shadow-size: 2px;
  isolation: isolate;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  outline-offset: 4px;
  opacity: 1;
  padding: 1.25rem 2.5rem;
  font-family: inherit;
  font-size: 1.125rem;
  line-height: 1.2;
  border: 1px solid transparent;
  border-radius: 15px;
  color: var(--shiny-cta-fg);
  background: linear-gradient(var(--shiny-cta-bg), var(--shiny-cta-bg)) padding-box,
    conic-gradient(from calc(var(--gradient-angle) - var(--gradient-angle-offset)),
      transparent,
      var(--shiny-cta-highlight) var(--gradient-percent),
      var(--gradient-shine) calc(var(--gradient-percent) * 2),
      var(--shiny-cta-highlight) calc(var(--gradient-percent) * 3),
      transparent calc(var(--gradient-percent) * 4)) border-box;
  box-shadow: inset 0 0 0 1px var(--shiny-cta-bg-subtle);

  &::before,
  &::after,
  span::before {
    content: "";
    pointer-events: none;
    position: absolute;
    inset-inline-start: 50%;
    inset-block-start: 50%;
    translate: -50% -50%;
    z-index: -1;
  }

  &:active {
    translate: 0 1px;
  }
}

/* Dots pattern */
.shiny-cta::before {
  --size: calc(100% - var(--shadow-size) * 3);
  --position: 2px;
  --space: calc(var(--position) * 2);
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle at var(--position) var(--position),
      white calc(var(--position) / 4),
      transparent 0) padding-box;
  background-size: var(--space) var(--space);
  background-repeat: space;
  mask-image: conic-gradient(from calc(var(--gradient-angle) + 45deg),
      black,
      transparent 10% 90%,
      black);
  border-radius: inherit;
  opacity: 0.4;
  z-index: -1;
}

/* Inner shimmer */
.shiny-cta::after {
  --animation: shimmer linear infinite;
  width: 100%;
  aspect-ratio: 1;
  background: linear-gradient(-50deg,
      transparent,
      var(--shiny-cta-highlight),
      transparent);
  mask-image: radial-gradient(circle at bottom, transparent 40%, black);
  opacity: 0.6;
}

.shiny-cta span {
  z-index: 1;

  &::before {
    --size: calc(100% + 1rem);
    width: var(--size);
    height: var(--size);
    box-shadow: inset 0 -1ex 2rem 4px var(--shiny-cta-highlight);
    opacity: 0;
  }
}

/* Animate */
.shiny-cta {
  --transition: 800ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: var(--transition);
  transition-property: --gradient-angle-offset, --gradient-percent,
    --gradient-shine;

  &,
  &::before,
  &::after {
    animation: var(--animation) var(--duration),
      var(--animation) calc(var(--duration) / 0.4) reverse paused;
    animation-composition: add;
  }

  span::before {
    transition: opacity var(--transition);
    animation: calc(var(--duration) * 1.5) breathe linear infinite;
  }
}

.shiny-cta:is(:hover, :focus-visible) {
  --gradient-percent: 20%;
  --gradient-angle-offset: 95deg;
  --gradient-shine: var(--shiny-cta-highlight-subtle);

  &,
  &::before,
  &::after {
    animation-play-state: running;
  }

  span::before {
    opacity: 1;
  }
}

@keyframes gradient-angle {
  to {
    --gradient-angle: 360deg;
  }
}

@keyframes shimmer {
  to {
    rotate: 360deg;
  }
}

@keyframes breathe {

  from,
  to {
    scale: 1;
  }

  50% {
    scale: 1.2;
  }
}

/* shiny button css end */

.rotating-image {
  animation: rotate 10s linear infinite;
  /* Adjust duration and timing function as needed */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");

.bubles {
  border-radius: 100%;
  position: absolute;
  pointer-events: none;
  background: #ffd700;
  box-shadow: 10px 10px 30px #ffdf00, -10px -10px 30px #fddc5c;
  transform: translate(-50%, -50%);
  animation: colorgen 1s infinite;
}

@keyframes colorgen {
  0% {
    opacity: 1;
    filter: hue-rotate(0deg);
  }

  100% {
    opacity: 0;
    filter: hue-rotate(270deg);
  }
}

.event-desc {
  background-color: #515691E5;
  color: white;
  /* min-height: 40vh; */
  padding: 20px;
  border-radius: 5px;
  /* width: 30vw; */
}

.poster {
  width: 200vh;
  /* border-radius: 5px; */
  /* margin-bottom: 20px; */
}

.bgstyle {
  flex-direction: column !important;
  background-image: url("./bgimg/finalPhone.jpeg") !important;
  background-position: center;
  overflow: scroll;
  /* width: 100vw !important; */
  background-size: cover !important;
  animation-name: moveBackground;
  /* Use animation-name */
  animation-duration: 250s;
  /* Specify animation duration */
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}



@media screen and (max-width: 768px) {
  @keyframes moveBackground {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 0 3000%;
    }
  }
}


.registerNow:hover {
  color: #ffd1ab !important;
  cursor: pointer;
}

.registerNow {
  text-decoration: underline;
}


@keyframes vibrate1 {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(-1px, -1px);
  }

  40% {
    transform: translate(1px, 1px);
  }

  60% {
    transform: translate(-1px, -1px);
  }

  80% {
    transform: translate(1px, 1px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes vibrate2 {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(1px, -1px);
  }

  40% {
    transform: translate(1px, -1px);
  }

  60% {
    transform: translate(1px, -1px);
  }

  80% {
    transform: translate(-1px, 1px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes vibrate3 {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(-1px, -1px);
  }

  40% {
    transform: translate(1px, -1px);
  }

  60% {
    transform: translate(1px, 1px);
  }

  80% {
    transform: translate(-1px, 1px);
  }

  100% {
    transform: translate(0, 0);
  }
}


@keyframes vibrate4 {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(-1px, 1px);
  }

  40% {
    transform: translate(1px, -1px);
  }

  60% {
    transform: translate(-1px, 1px);
  }

  80% {
    transform: translate(1px, -1px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.vibrate-animation1 {
  animation-duration: 0.2s;
  /* Adjust animation duration as needed */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: vibrate1;
}

.vibrate-animation2 {
  animation-duration: 0.2s;
  /* Adjust animation duration as needed */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: vibrate2;
}

.vibrate-animation3 {
  animation-duration: 0.2s;
  /* Adjust animation duration as needed */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: vibrate3;
}

.vibrate-animation4 {
  animation-duration: 0.2s;
  /* Adjust animation duration as needed */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: vibrate4;
}


@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden !important;
    max-width: 100vw !important;

  }

  .bgstyle {
    max-width: 100vw !important;
    overflow-x: hidden;
  }

  .aboutus {
    margin-top: 11vh;
    width: 95vw !important;
    margin-bottom: 11vh;
  }

  .aboutuspara {
    font-size: 20px !important;
  }

  .image-apni {
    display: none !important;
  }

  .sponsors {
    width: 95vw !important;
    max-width: 95vw !important;
    margin-bottom: 11vh;
    display: flex !important;
    flex-direction: column !important;
    margin-top: -20vh;
  }

  .clock-apni {
    display: none !important;
  }


  .smoke-apni {
    display: none !important;
  }

  .speakers {
    width: 95vw !important;
  }

  .speaker-heading {
    margin-top: 8vh;
    font-size: 45px !important;
    text-align: center;
    margin-bottom: 15vh;
  }

  .main-con {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .con-main {
    margin-bottom: 45vh !important;
  }

  .event-title {
    height: auto !important;
  }

  .event-description {
    height: auto !important;
  }


  .if-phone {
    flex-direction: column !important;
    /* margin: 100px !important; */
    padding: 0 !important;
  }

  .poster {
    margin: 0 !important;
    padding: 0 !important;
    margin-bottom: 5vh !important;
    width: 100% !important;
  }

  .youtube {
    /* margin-bottom: 5vh;
        margin-top: 10vh; */
    margin: 0;
  }

  .event-image a {
    left: 15% !important;
  }

  .Event_Heading {
    font-size: 48px;
  }

  .coming_soon {
    font-size: 2rem;
    font-weight: 100;
  }
}



.link-sponsor-underline {
  text-decoration: none !important;
}


.field-input {
  width: 90%;
  background-color: #7894ac;
  border: 3px solid white;
  color: black;
  padding: 2%;
  border-radius: 5px;
  margin-top: 2%;
  margin-bottom: 2%;
}

.fields-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@import url('https://fonts.googleapis.com/css2?family=Young+Serif&display=swap');

.dateHeading {
  font-family: 'Young Serif', serif;
  color: #fcbe6e;
  text-shadow: black 0.1em 0.1em 0.2em;
}


.heightFont {
  font-size: 2.5rem !important;
}


.event-speakers {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.event-speaker-list ul {
  padding-left: 0;
}

.yellow-box {
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  text-align: center;
}

.event-speaker-heading {
  font-size: 50px;
  /* font-weight: bold; */
  margin-bottom: 50px;

  color: #141629;
  font-family: 'Japan Daisuki Regular', sans-serif;
}

.event-speaker-item {
  display: inline-block;
  /* margin-right: 20px; */
  vertical-align: top;
}

.event-speaker-image {
  width: 250px;
  height: 250px;
  border: 2px solid #ffd1ab;
  border-radius: 10%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.event-speaker-name {
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0 !important;
}

.event-speaker-ka-desc {
  font-size: medium;
  background-color: transparent;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* padding: 1em; */
  border-radius: 10px;
}



.event-speakers-section {
  margin-top: 10vh !important;
}


.event-list-container::-webkit-scrollbar {
  width: 5px;
  /* Adjust the width as needed */
}

.event-list-container::-webkit-scrollbar-track {
  background: #392828;
  /* Change the background color */
}

.event-list-container::-webkit-scrollbar-thumb {
  background: #fcbe6e;
  /* Change the thumb color */
  border-radius: 8px;
  /* Adjust the border radius as needed */
}

.event-list-container::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Change the thumb color on hover */
}

.event-speakers-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.event-speaker-item {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  margin-bottom: 10vh;
  overflow-x: hidden;
  margin: 5vw;
}

.event-speaker-info {
  background-color: #3f47a0;
  min-height: 25vh;
  height: 'fit-content';
  border: 5px solid white;
  padding: 10px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .event-speaker-item {
    margin: 2vw;
  }

  .event-speakers-list {
    flex-direction: column;
  }

  .event-speakers-list-container {
    overflow-x: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .event-speakers-section {
    margin-top: 0 !important;
  }

  .event-speaker-heading {
    margin-top: 0 !important;
  }
}


.carousel-control-next,
.carousel-control-prev {
  top: 0px !important;
  bottom: 0vh !important;
}

@media screen and (max-width: 1110px) {
  .carousel-indicators {
    bottom: 28vh !important;
  }
}

@media screen and (max-width: 900px) {
  .event-description {
    font-size: 0.8em;
  }

  .event-title {
    font-size: 1.5em;
  }

  .event-image img {
    width: 250px !important;
    height: 250px;
  }
}

@media screen and (max-width: 770px) {
  .carousel-indicators {
    bottom: 40vh !important;
    /* overflow-x: hidden; */
  }


}

.event-head {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Kadwa", serif;
  font-size: 2em;
  margin: 10vh 10vw;
  border: 5px solid white;
  background-color: #7d81b1e5;
  opacity: 0.9;
  /* width: fit-content; */
}

.event-img {
  /* border-right: 5px solid white; */
  padding: 10px;
}

@media screen and (min-width: 1025px) {
  .lap-ht {
    aspect-ratio: 16 / 9 !important;
    
  }

  
}