.navbarNew{
    display: flex;
    justify-items: center;
    align-items: flex-start;
    height: 30%;
    
}

.homeFlag{
    height: 100%;
    width: 10%;
    /* background-color: rgba(0, 255, 255, 0.852); */
    cursor: pointer;
}

.spaceHome{
    height: 100%;
    width: 55%;
    /* background-color: rgba(236, 197, 125, 0.692); */
}

.navitems{      
    height: 80%;
    width: 22%;
    /* background-color: rgba(217, 0, 255, 0.514); */
    display: flex;
    justify-content: space-evenly;
    align-items: top;
    font-family: 'Sylfaen', sans-serif;
}

.profileHome{
    height: 100%;
    width: 13%;
    /* background-color: rgba(144, 0, 255, 0.341); */
    display: flex;
    justify-content: flex-end;
    padding-right: 2%;
    margin-top: 1%;
}


.navitem1{
    height: 90%;
    width: 30%;
    background-image: url('./photos24/offshort.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    font-size: 90%;   
    font-weight: 600;
    color: #700815;
    /* padding: 25px 0; */
}

.navitem2{
    height: 100%;
    width: 30%;
    background-image: url('./photos24/offlong.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    font-size: 90%;   
    font-weight: 600;
    color: #700815;
    /* padding: 50px 0; */
    padding-top: 18px;

}
.letterhome{
    height: 16px;
}

.navitem3{height : 90%;width: 30%;
    background-image: url('./photos24/offshort.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    font-size: 90%;   
    font-weight: 600;
    color: #700815;
}

.active1{
    transition: 0.15s;
    background-image: url('./photos24/onshort.png');

}

.active2{
    transition: 0.15s;

    background-image: url('./photos24/onLong.png');

}.active3{
    transition: 0.15s;

    background-image: url('./photos24/onshort.png');

}

.navitem1:hover{
    background-image: url('./photos24/onshort.png');
    transition: 0.15s;
}
.navitem2:hover{
    background-image: url('./photos24/onLong.png');
    transition: 0.15s;
}.navitem3:hover{
    background-image: url('./photos24/onshort.png');
    transition: 0.15s;
}



@media screen and (max-width: 1286px) {
    .spaceHome{
        height: 100%;
        width: 53%;
        /* background-color: rgba(236, 197, 125, 0.692); */
    }
    
    .navitems{      
        height: 80%;
        width: 24%;

    }


}


@media screen and (max-width: 1200px) {
    .spaceHome{
        height: 100%;
        width: 48%;
        /* background-color: rgba(236, 197, 125, 0.692); */
    }
    
    .navitems{      
        height: 80%;
        width: 27%;

    }
    .homeFlag{
        width: 12%;
    }
    


}
@media screen and (max-width: 1060px) {
    .spaceHome{
        height: 100%;
        width: 46%;
        /* background-color: rgba(236, 197, 125, 0.692); */
    }
    
    .navitems{      
        height: 75%;
        width: 30%;

    }
    .homeFlag{
        width: 13%;
    }


}

@media screen and (max-width: 900px) {
    .spaceHome{
        height: 100%;
        width: 45%;
        /* background-color: rgba(236, 197, 125, 0.692); */
    }
    
    .navitems{      
        height: 75%;
        width: 30%;

    }
    .homeFlag{
        width: 14%;
    }


}


.menuHamburger{
    height: 65px;
    width: 65px ;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #700815;
    z-index: 1000;
    transition: background-color 1s ease;
}

.menuProfile{


    position: fixed;
    height: 300px;
    width: 300px;
    background-color: rgba(239, 200, 117, 0.451);
    top: -130px;
    right: -130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #700815;
    font-size: 130%;
    font-weight: 600;
    animation: slideIn 0.8s ease-out forwards;
    border-radius: 50%;
    font-family: 'Sylfaen', sans-serif;

}

.scheduleLaptop{
    cursor: pointer;
    position: relative;
    top: 80px;
    right: 40px;
}
.scheduleLaptop:hover{
    color: rgb(255, 255, 255);
}
.menuLapProfile{
    cursor: pointer;
    position: relative;
    top: 75px;
    right: 90px;
}
.menuLapProfile:hover{
    color: rgb(255, 255, 255);
}
.menuLapLogin{
    position: relative;
    top: 75px;
    right: 90px;
    cursor: pointer;
}
.menuLapLogin:hover{
    color: rgb(255, 255, 255);
}

@media  screen and (max-width: 1225px) {

    .menuProfile{
        top: -140px;
        right: -140px;
    }

    .menuLapProfile,.menuLapLogin{
        top: 85px;
        right: 90px;
    }

    .scheduleLaptop{
        top: 85px;
        right: 40px;
    }
}

@media  screen and (max-width: 1000px) {

    .menuProfile{
        top: -150px;
        right: -150px;
    }

    .menuLapProfile,.menuLapLogin{
        top: 90px;
        right: 80px;
    }

    .scheduleLaptop{
        top: 87px;
        right: 50px;
    }
}

@media  screen and (max-width: 1000px) {

    .menuProfile{
        top: -140px;
        right: -160px;
    }

    .menuLapProfile,.menuLapLogin{
        top: 90px;
        right: 50px;
    }

    .scheduleLaptop{
        top: 87px;
        right: 50px;
    }
}




@keyframes slideIn {
    from {
      transform: translate(200px, -200px);
      opacity: 0;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }


.activate{
    color: white;
}
